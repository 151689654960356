// /pages/login.js
import { useRouter } from "next/router";
import { withAuthenticator, useAuthenticator } from "@aws-amplify/ui-react";

function Login() {
    const router = useRouter();
    const { ref } = router.query;
    const { authStatus } = useAuthenticator((context) => [context.user]);

    return <></>;
}

export default withAuthenticator(Login);